import * as Sentry from '@sentry/astro';

const is_production = import.meta.env.PUBLIC_VERCEL_ENV === 'production';

if (is_production) {
    Sentry.init({
        release:                  import.meta.env.VERCEL_GIT_COMMIT_SHA,
        dsn:                      'https://6706cc5964c7381a7a9c1331ea6ffad7@o4506913952563200.ingest.us.sentry.io/4507295309168640',
        integrations:             [
            Sentry.replayIntegration({
                maskAllText:   false,
                blockAllMedia: false,
            }),
            Sentry.browserTracingIntegration(),
        ],
        tracesSampleRate:         1.0,
        replaysSessionSampleRate: 0.05,
        replaysOnErrorSampleRate: 1.0,
    });
}
